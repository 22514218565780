<template>
  <div class="word">
    <van-form @submit="onSubmit" class="wordForm">
      <van-cell-group inset class="bgCell">
        <van-field :border='false' readonly name="picker"
          right-icon="arrow-down" v-model="wordForm.platformName" label="媒体名称"
          placeholder="点击选择媒体" @click="setColumns('platformId')"
          :rules="[{ required:true, message: '请选择媒体名称' }]" />
        <van-field :border='false'
          @input="resetCode"
          v-model="wordForm._platformName" center clearable label="媒体账号"
          placeholder="点击添加账号" :rules="[{ required:true, message: '请添加媒体账号' }]">
          <template #button>
            <van-icon @click="setColumns('platformCode')" name="arrow-down"
              class='xiala' />
          </template>
        </van-field>
      </van-cell-group>
      <van-cell-group inset class="bgCell">
        <van-field :border='false' v-model="wordForm.promotionUrl" label="作品链接"
          placeholder=""
          :rules="[{ validator:validatorUrl, message: '请填写正确的作品链接' }]" />
        <van-field readonly :border='false' name="calendar"
          v-model="wordForm.promotionDate" label="发布日期" placeholder="点击选择日期"
          @click="showCalendar = true"
          :rules="[{  required: true, message: '请选择发布日期' }]" />
      </van-cell-group>
      <van-button block native-type="submit" class="submitBtn" :loading="loading">
        提交
      </van-button>
    </van-form>
  </div>
  <van-popup v-model:show="showPicker" position="bottom">
    <van-picker show-toolbar :columns="columns" @confirm="onConfirm"
      @cancel="showPicker = false" />
  </van-popup>
  <van-calendar v-model:show="showCalendar" :minDate='minDate'
    :max-date="maxDate" :default-date='new Date(wordForm.promotionDate)'
    @confirm="setDate" />
</template>

<script>
import { Toast } from 'vant'
import { getPromotionPlatform } from '@/api/public'
import { addTaskPromotion } from '@/api/task'

export default {
  data() {
    return {
      wordForm: {
        platformId: '',
        platformName: '',  // 只显示 不提交
        _platformName: '',  // 只显示 不提交
        platformCode: '',
        promotionUrl: '',
        promotionDate: ''
      },
      // 平台列表
      columns: [],
      platformList: [],
      kocList: [],
      showPicker: false,
      showCalendar: false,
      pickerTitle: '',
      maxDate: new Date(),
      loading: false
    }
  },
  computed: {
    // 设置发布日期范围
    minDate() {
      let currentDate = new Date()
      currentDate.setDate(currentDate.getDate() - 7)
      return currentDate
    },
  },
  async mounted() {
    this.getPromotionPlatform()
  },
  methods: {
    // 获取下拉列表
    async getPromotionPlatform() {
      let list = await getPromotionPlatform({status: 10})
      this.platformList = list.map(item => {
        return {
          id: item.id,
          text: item.name,
          childList: item.kocPromotion
        }
      })
      this.columns = JSON.parse(JSON.stringify(this.platformList))
    },
    // 设置下拉菜单
    setColumns(title) {
      this.showPicker = true
      if (title == 'platformId') {
        this.columns = JSON.parse(JSON.stringify(this.platformList))
      } else {
        this.columns = JSON.parse(JSON.stringify(this.kocList))
      }
      this.pickerTitle = title
    },
    onConfirm(value) {
      if (this.pickerTitle == 'platformId') {
        // 判断是否选新的媒体了，换媒体选项就重置媒体账号及作品链接
        this.wordForm.platformId = value.id
        this.wordForm.platformName = value.text
        this.wordForm.platformCode = ''
        this.wordForm._platformName = ''
        this.kocList = value.childList.map(item => {
          return {
            id: item.platform_code,
            text: item.platform_code
          }
        })
      } else {
        this.wordForm.platformCode = value.id
        this.wordForm._platformName = value.text
      }
      this.showPicker = false
    },
    setDate(date) {
      this.wordForm.promotionDate = `${date.getFullYear()}/${
        date.getMonth() + 1
      }/${date.getDate()}`
      this.showCalendar = false
    },
    async onSubmit() {
      const { _platformName, platformName, platformCode, ...form } = this.wordForm
      this.loading = true
      await addTaskPromotion({
        platformCode: platformCode || _platformName,
        ...form,
        taskId: this.$route.query.id
      })
      this.loading = false
      Toast('提交成功')
      setTimeout(() => {
        this.$router.back(-1)
      }, 500)
    },
    resetCode() {
      this.wordForm.platformCode = ''
    },
    validatorUrl(text) {
      let val = text.trim()
      let strRegexp = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/
      if (!strRegexp.test(val)) {
        return false
      }
      let REarr = val.match(strRegexp)
      let url = REarr.find(item => strRegexp.test(item))
      this.wordForm.promotionUrl = url
    }
  }
}
</script>

<style lang='less' scoped>
.word {
  padding: 30px;
  min-height: 100%;
  background: white;
  .wordForm {
    .van-cell {
      padding-left: 0px;
      padding-right: 0px;
      /deep/ .van-cell__title {
        width: 140px;
        font-size: 30px !important;
        font-weight: bold !important;
        margin: 0px;
      }
    }
    .bgCell {
      /deep/ .van-cell__value {
        background: #f3f3f3;
        padding: 5px 20px;
        border-radius: 10px;
      }
    }
  }
  .xiala {
    padding-left: 30px;
    border-left: 2px solid #9c9cac;
    font-size: var(--van-field-icon-size);
  }
  .submitBtn {
    margin: 80px auto;
    width: 360px;
    height: 72px;
    font-size: 34px;
    background: #ff855d;
    color: white;
  }
}
</style>